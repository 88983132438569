import styled from 'styled-components';

export const Container = styled.div.attrs<IWebp>(({ $isWebpEnabled }) => ({
  style: {
    backgroundImage: $isWebpEnabled
      ? `url('/images/intro/webp/background.webp')`
      : `url('/images/intro/background.jpg')`,
  },
}))<IWebp>`
  flex: 1;
  min-height: 100vh;

  background-color: ${({ theme }) => theme.colors.white};
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100%;

  & > div {
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 10%);
    background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 10%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 10%);

    height: 50vh;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    padding: 1rem;

    svg {
      width: 16rem;
      max-width: 100%;
    }
  }
`;
